import React from "react";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer>
            &copy; 2022 - {currentYear}
        </footer>
    )
}

export default Footer;