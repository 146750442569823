import React from "react";

const Welcome = () => {
    return (
        <div className="box right">
            <section className="box-desc" id="Welcome">
                <article>
                    <h2 className="title">Halo, Selamat datang di Portofolio Saya</h2>
                    <p className="hr-item" />
                    <p>
                        Perkenalkan, nama saya Budi Prasetyo. 
                        <br /> Saya adalah seorang Web Developer 
                        dengan pengalaman dalam membantu bisnis berkembang melalui teknologi. 
                        <br /><br />Saat ini, saya aktif mengembangkan aplikasi untuk Toko Elektronik dan sembako. 
                        Saya percaya bahwa teknologi adalah kunci untuk meningkatkan efisiensi dan memperluas jangkauan bisnis. 
                    </p>
                    <p>
                        <br />Dengan fokus pada kebutuhan unik setiap klien, saya menikmati merancang solusi inovatif 
                        yang dapat membantu bisnis Anda bertumbuh dan mencapai tujuan. Mari bekerja sama untuk 
                        menciptakan aplikasi yang memberikan dampak nyata!
                    </p>
                </article>
                <p className="text-email">budi.kerja8@gmail.com</p>
                {/* <p className="text-muted">09 Februari 2023</p> */}
            </section>
        </div>
    )
}

export default Welcome;